import React, { useRef } from "react";
import "./styles.css";

import { useNavigate } from "react-router-dom";

import Enviar from "../../assets/enviar.png";
import EnviarMobile from "../../assets/mobile/enviar.png";

const Form = () => {
  const formRef = useRef(null);
  const navigate = useNavigate();

  const codigo =
    "AKfycbyfcwPSj4K6dup-TobIROurEojzSZijVTrojqAPoj4fVjC8cYJfXz4vOE5FBtTl1zrW";
  const scriptURL = `https://script.google.com/macros/s/${codigo}/exec`;

  const phoneMask = (e) => {
    if (e.target.value.length < 10) {
      return alert("Número inválido");
    } else if (e.target.value.length === 10) {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{4})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    } else {
      const x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{2})(\d{5})(\d{4})/);
      e.target.value = "(" + x[1] + ") " + x[2] + "-" + x[3];
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    document.getElementById("submit").disabled = true;
    document.getElementById("submit").style.cursor = "default";
    document.getElementById("submit-image").style.opacity = 0.5;

    fetch(scriptURL, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then(() => {
        document.getElementById("form").reset();
        navigate("/sucesso");
      })
      .catch((err) => console.log(err));
  };

  return (
    <form ref={formRef} name="google-sheet" onSubmit={handleSubmit} id="form">
      <div className="form-div1">
        <label htmlFor="name">
          Nome: <span>*</span>
        </label>
        <input type="text" id="name" name="Nome" required />

        <label htmlFor="email">
          E-mail: <span>*</span>
        </label>
        <input type="email" id="email" name="Email" required />

        <label htmlFor="tel">
          Whatsapp/Celular: <span>*</span>
        </label>

        <input
          type="tel"
          id="tel"
          name="Whatsapp/Celular"
          onBlur={phoneMask}
          minLength="10"
          maxLength="11"
          required
        />

        <label htmlFor="local">
          Onde você quer que seu anúncio apareça: <span>*</span>
        </label>
        <select
          id="local"
          name="Onde você quer que seu anúncio apareça"
          required
        >
          <option value=""></option>
          <option value="Google">Google</option>
          <option value="Facebook e Instagram">Facebook e Instagram</option>
          <option value="LinkedIn">LinkedIn</option>
          <option value="TikTok">TikTok</option>
          <option value="Youtube">Youtube</option>
          <option value="Mais de uma Mídia">Mais de uma Mídia</option>
        </select>

        <label htmlFor="investimento">
          Investimento em Campanha: <span>*</span>
        </label>
        <select id="investimento" name="Investimento em Campanha" required>
          <option value=""></option>
          <option value="Até R$500/mês">Até R$500/mês</option>
          <option value="De R$ 500 a R$ 1000/mês">
            De R$ 500 a R$ 1000/mês
          </option>
          <option value="De R$ 1.000 a R$ 2000/mês">
            De R$ 1.000 a R$ 2000/mês
          </option>
          <option value="De R$ 2.000 a R$ 4000/mês">
            De R$ 2.000 a R$ 4000/mês
          </option>
          <option value="Acima de R$ 4000/mês">Acima de R$ 4000/mês</option>
        </select>

        <div className="form-div3">
          <p className="title">Serviços adicionais</p>

          <div className="row">
            <div className="item">
              <input
                type="checkbox"
                id="desenvolvimento"
                name="Serviços Adicionais"
                value="Desenvolvimento de site"
              />
              <label htmlFor="desenvolvimento">Desenvolvimento de site</label>
            </div>

            <div className="item">
              <input
                type="checkbox"
                id="gestao"
                name="Serviços Adicionais"
                value="Gestão de conteúdo"
              />
              <label htmlFor="gestao">Gestão de conteúdo</label>
            </div>
          </div>
        </div>
      </div>

      <div className="form-div2">
        <div className="checkbox">
          <input
            type="checkbox"
            id="check"
            name="Contato Comercial"
            value="Quero"
            defaultChecked
          />
          <label htmlFor="check">Aceito receber contato comercial</label> <br />
          <button type="submit" id="submit">
            <figure>
              <img
                src={
                  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                  )
                    ? EnviarMobile
                    : Enviar
                }
                alt="Enviar"
                id="submit-image"
                height={50}
              />
            </figure>
          </button>
        </div>
      </div>

      <input type="hidden" name="Local de Origem" value="Youtube" />
    </form>
  );
};

export default Form;
